import React from "react";
import { Link } from 'gatsby';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {  createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import Drawer from '@material-ui/core/Drawer';
// import { View } from "react-native";
import CommitHash from './CommitHashMember';
import CommitMessage from './CommitMessageMember';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Divider from '@material-ui/core/Divider';
import AddMember from './AddMember';
import RemoveMember from './RemoveMember';
import AspireMember from './AspireMember';
import RemoveAspireMember from './RemoveAspireMember';

const Hash = require('pure-ipfs-only-hash')
// const CID = require('cids')
// const multihashing = require('multihashing-async')


const theme = createMuiTheme({
  palette: {
    primary: grey,
  },
  typography: {
    useNextVariants: true,
  },
});

const button_style = {
  button: {
    borderRadius: 0,
    backgroundColor: "black",
    color: 'white',
    padding: "9px 36px",
    fontSize: "24px",
    fontWeight: 'bold',
    textTransform: 'capitalize',
    boxShadow: '0 0px 0px 0px',
    minWidth: 300,
  },
  buttonDisabled: {
    borderRadius: 0,
    backgroundColor: '#EBECF0',
    color: 'white',
    padding: "9px 36px",
    fontSize: "24px",
    fontWeight: 'bold',
    textTransform: 'capitalize',
    boxShadow: '0 0px 0px 0px',
    minWidth: 300,
  },
};

/*
const button_style_pop_up = {
  button: {
    borderRadius: 0,
    backgroundColor: "black",
    color: 'white',
    padding: "9px 36px",
    fontSize: "24px",
    fontWeight: 'bold',
    textTransform: 'capitalize',
    boxShadow: '0 0px 0px 0px',
    minWidth: 300,
  },
  buttonDisabled: {
    borderRadius: 0,
    backgroundColor: '#EBECF0',
    color: 'white',
    padding: "9px 36px",
    fontSize: "24px",
    fontWeight: 'bold',
    textTransform: 'capitalize',
    boxShadow: '0 0px 0px 0px',
    minWidth: 300,
  },
};
*/

const snackbar_style = {
  standardMessage: {
    borderRadius: 0,
    backgroundColor: '#EBECF0',
    color: 'black',
    padding: "8px 20px",
    fontSize: "20px",
    fontWeight: 'light',
    textTransform: 'capitalize',
    boxShadow: '0 0px 0px 0px',
    minWidth: 145,
  }
};

const PopUp = props => {
  if (props.popUpState === false) {
    return null
  }
  return (
    <Snackbar
        open
        >
          <SnackbarContent
            style={{
             ...snackbar_style.standardMessage
             }}
            message={
              <span id="client-snackbar">
              {props.popUpMessage}
              </span>}
            action={[
              <IconButton key="close" aria-label="close" color="inherit" onClick={() => props.handlePopUpClick()}>
                <CloseIcon className='close-icon' />
              </IconButton>
            ]}
          />
    </Snackbar>
  )
}

class App extends React.Component {
  state = {
    text_to_hash: '',
    hash_output: '',
    calculateOk: false,
    submitOk: false,
    submitDrawerOpen: false,
    popUp: false,
    popUpMessage: '',
    stackId: null,
    web3_initiated: false,
    submitButtonActive: false,
    submitButtonActiveCID: false,
    messageToSumbit: '',
    messageButtonActive: false,
    guild: '',
    memberAddress: '',
    addMemberButtonActive: false,
    addMemberDrawerOpen: false,
    removeMemberDrawerOpen: false,
    aspireMemberDrawerOpen: false,
    removeAspireMemberDrawerOpen: false
  }

  //async componentDidMount() {
  //  const response = await fetch(`https://api.coinmarketcap.com/v1/ticker/?limit=10`);
  //  const json = await response.json();
  //  this.setState({ data: json });
  //}

  handleTextChange = (text) => {
    if (text === '') { this.setState({ submitButtonActive: false, hash_output: '', popUpMessage: '', popUp: false }) }
    this.setState({ text_to_hash: text, popUpMessage: '', popUp: false })
  }

  handleTextChangeGuild = (text) => {
    if (text === '') { this.setState({ guild: text, popUpMessage: '', popUp: false }) } else 
    this.setState({ guild: text, popUpMessage: '', popUp: false })
  }

  handleMessageChange = (text) => {
    if (text === '') { this.setState({ messageToSumbit: text, messageButtonActive: false, popUpMessage: '', popUp: false }) } else
    this.setState({ messageToSumbit: text, popUpMessage: '', popUp: false, messageButtonActive: true })
  }

  handleAddMemberChange = (text) => {
    if (text === '') { this.setState({ memberAddress: text, addMemberButtonActive: false, popUpMessage: '', popUp: false }) } else 
    this.setState({ memberAddress: text, popUpMessage: '', popUp: false, addMemberButtonActive: true })
  }

  handleTextChangeCID = (text) => {
    if (text === '') { this.setState({ submitButtonActiveCID: false, popUpMessage: '', popUp: false, hash_output: text }) } else 
    this.setState({ hash_output: text, popUpMessage: '', popUp: false, submitButtonActiveCID: true })
  }

  async handleHashClick() {
    const hash = await Hash.of(this.state.text_to_hash, {cidVersion: 1, rawLeaves: true})
    // const cid_v0 = new CID(hash)
    // const cid_v1 = cid_v0.toV1().toString()
    // const bytes = new TextEncoder('utf8').encode(this.state.text_to_hash)

    // const hash = await multihashing(bytes, 'sha2-256')
    // const cid = new CID(1, 'dag-pb', hash)
    // const cid_v1 = cid.toString()
    this.setState({ hash_output: hash, submitButtonActive: true, popUpMessage: '', popUp: false })
  }

  handleSubmitHashClick = () => {
    this.setState({ popUpMessage: '', popUp: false })
    this.setState( { submitDrawerOpen: true } )
    console.log(this.state.submitDrawerOpen)
  }

  handleMessageClick = () => {
    this.setState({ popUpMessage: '', popUp: false })
    this.setState( { messageDrawerOpen: true } )
    console.log(this.state.messageDrawerOpen)
  }

  handleAddMemberClick = () => {
    this.setState({ popUpMessage: '', popUp: false })
    this.setState( { addMemberDrawerOpen: true } )
    console.log(this.state.messageDrawerOpen)
  }

  handleRemoveMemberClick = () => {
    this.setState({ popUpMessage: '', popUp: false })
    this.setState( { removeMemberDrawerOpen: true } )
    console.log(this.state.messageDrawerOpen)
  }

  handleAspireMemberClick = () => {
    this.setState({ popUpMessage: '', popUp: false })
    this.setState( { aspireMemberDrawerOpen: true } )
    console.log(this.state.messageDrawerOpen)
  }

  handleRemoveAspireMemberClick = () => {
    this.setState({ popUpMessage: '', popUp: false })
    this.setState( { removeAspireMemberDrawerOpen: true } )
    console.log(this.state.messageDrawerOpen)
  }

  handleDismissClick = () => {
    this.setState({submitDrawerOpen: false, popUpMessage: '', popUp: false, messageDrawerOpen: false, addMemberDrawerOpen: false,
                   removeMemberDrawerOpen: false, aspireMemberDrawerOpen: false, removeAspireMemberDrawerOpen: false})
  }

  handlePopUpClick = () => {
    this.setState({ popUp: false, popUpMessage: '' })
  }
  
  handleWeb3Error = (error) => {
    this.setState({ popUp: true, popUpMessage: error, submitDrawerOpen: false, messageDrawerOpen: false })
  }

  render() {
    let CollapsibleComponent;
    let CollapsibleHead;
    let CollapsibleContent;
    if (typeof window !== 'undefined') {
      CollapsibleComponent = require('react-collapsible-component').CollapsibleComponent;
      CollapsibleHead = require('react-collapsible-component').CollapsibleHead;
      CollapsibleContent = require('react-collapsible-component').CollapsibleContent;
    }
    if (!CollapsibleComponent) { return <p>Beep boop</p>; }
    return (
      <div style={{ maxWidth: `1200px`, marginBottom: `1.45rem` , 'marginLeft': '4px', 'marginRight': '4px'}} align="left">
          <div className="TextField-without-border-radius">
          <MuiThemeProvider theme={theme}>
          <TextField
            inputProps={{inputMode: 'text', style: {fontSize: 20, lineHeight: 1.2}}}
            id="text"
            value={this.state.guild}
            onChange={event => this.handleTextChangeGuild(event.target.value)}
            type="text"
            margin="normal"
            placeholder="Enter guild name..."
            style = {{width: '100%', fontSize: 20}} 
            variant="outlined"
          />
          </MuiThemeProvider>
          </div>
          <div className='collapse'>
          <CollapsibleComponent>
          <CollapsibleHead><h1>Calculate CID from text and submit</h1></CollapsibleHead>
          <CollapsibleContent>
          <div className="TextField-without-border-radius">
          <MuiThemeProvider theme={theme}>
          <TextField
            inputProps={{inputMode: 'text', style: {fontSize: 20, lineHeight: 1.2}}}
            id="text"
            multiline rows={7}
            value={this.state.text_to_hash}
            onChange={event => this.handleTextChange(event.target.value)}
            type="text"
            margin="normal"
            placeholder="Type anything..."
            style = {{width: '100%', fontSize: 20}} 
            variant="outlined"
          />
          </MuiThemeProvider>
          </div>
          <div>
          <Button
           style={{
            ...button_style.button
           }}
           onClick={() => this.handleHashClick()}>Calculate CID
          </Button>
          <p> </p>
          <Button
           disabled={!this.state.submitButtonActive}
           style={{
            ...button_style.button,
            ...(!this.state.submitButtonActive ? button_style.buttonDisabled : {}),
           }}
           onClick={() => this.handleSubmitHashClick()}>Submit CID
          </Button>
          </div>
          <div style={{ wordBreak: "break-word" }}>
          <h4>{this.state.hash_output}</h4>
          <p> </p>
          </div>
          </CollapsibleContent>
          <Divider/>
          <CollapsibleHead><h1>Submit CID</h1></CollapsibleHead>
          <CollapsibleContent>
          <div className="TextField-without-border-radius">
          <MuiThemeProvider theme={theme}>
          <TextField
            inputProps={{inputMode: 'text', style: {fontSize: 20, lineHeight: 1.2}}}
            id="text"
            multiline rows={2}
            value={this.state.hash_output}
            onChange={event => this.handleTextChangeCID(event.target.value)}
            type="text"
            margin="normal"
            placeholder="Enter CID..."
            style = {{width: '100%', fontSize: 20}} 
            variant="outlined"
          />
          </MuiThemeProvider>
          </div>
          <div>
          <Button
           disabled={!this.state.submitButtonActiveCID}
           style={{
            ...button_style.button,
            ...(!this.state.submitButtonActiveCID ? button_style.buttonDisabled : {}),
           }}
           onClick={() => this.handleSubmitHashClick()}>Submit CID
          </Button>
          <p> </p>
          </div>
          </CollapsibleContent>
          <Divider/>
          <CollapsibleHead><h1>Submit message</h1></CollapsibleHead>
          <CollapsibleContent>
          <div className="TextField-without-border-radius">
          <MuiThemeProvider theme={theme}>
          <TextField
            inputProps={{inputMode: 'text', style: {fontSize: 20, lineHeight: 1.2}}}
            id="text"
            multiline rows={6}
            value={this.state.messageToSumbit}
            onChange={event => this.handleMessageChange(event.target.value)}
            type="text"
            margin="normal"
            placeholder="Enter message (max 333 characters)..."
            style = {{width: '100%', fontSize: 20}} 
            variant="outlined"
          />
          </MuiThemeProvider>
          </div>
          <div>
          <Button
           disabled={!this.state.messageButtonActive}
           style={{
            ...button_style.button,
            ...(!this.state.messageButtonActive ? button_style.buttonDisabled : {}),
           }}
           onClick={() => this.handleMessageClick()}>Submit Message
          </Button>
          <p> </p>
          </div>
          </CollapsibleContent>
          <Divider/>
          <CollapsibleHead><h1>Add member</h1></CollapsibleHead>
          <CollapsibleContent>
          <div className="TextField-without-border-radius">
          <MuiThemeProvider theme={theme}>
          <TextField
            inputProps={{inputMode: 'text', style: {fontSize: 20, lineHeight: 1.2}}}
            id="text"
            multiline rows={2}
            value={this.state.memberAddress}
            onChange={event => this.handleAddMemberChange(event.target.value)}
            type="text"
            margin="normal"
            placeholder="Enter member address..."
            style = {{width: '100%', fontSize: 20}} 
            variant="outlined"
          />
          </MuiThemeProvider>
          </div>
          <div>
          <Button
           disabled={!this.state.addMemberButtonActive}
           style={{
            ...button_style.button,
            ...(!this.state.addMemberButtonActive ? button_style.buttonDisabled : {}),
           }}
           onClick={() => this.handleAddMemberClick()}>Add Member
          </Button>
          <p> </p>
          </div>
          </CollapsibleContent>
          <Divider/>
          <CollapsibleHead><h1>Remove member</h1></CollapsibleHead>
          <CollapsibleContent>
          <div className="TextField-without-border-radius">
          <MuiThemeProvider theme={theme}>
          <TextField
            inputProps={{inputMode: 'text', style: {fontSize: 20, lineHeight: 1.2}}}
            id="text"
            multiline rows={2}
            value={this.state.memberAddress}
            onChange={event => this.handleAddMemberChange(event.target.value)}
            type="text"
            margin="normal"
            placeholder="Enter member address..."
            style = {{width: '100%', fontSize: 20}} 
            variant="outlined"
          />
          </MuiThemeProvider>
          </div>
          <div>
          <Button
           disabled={!this.state.addMemberButtonActive}
           style={{
            ...button_style.button,
            ...(!this.state.addMemberButtonActive ? button_style.buttonDisabled : {}),
           }}
           onClick={() => this.handleRemoveMemberClick()}>Remove Member
          </Button>
          <p> </p>
          </div>
          </CollapsibleContent>
          <Divider/>
          <CollapsibleHead><h1>Aspire membership</h1></CollapsibleHead>
          <CollapsibleContent>
          <div>
          <Button
           style={{
            ...button_style.button
           }}
           onClick={() => this.handleAspireMemberClick()}>Aspire Membership
          </Button>
          <p> </p>
          </div>
          </CollapsibleContent>
          <Divider/>
          <CollapsibleHead><h1>Remove aspiring membership</h1></CollapsibleHead>
          <CollapsibleContent>
          <div>
          <Button
           style={{
            ...button_style.button
           }}
           onClick={() => this.handleRemoveAspireMemberClick()}>Remove Aspiring Membership
          </Button>
          <p> </p>
          </div>
          </CollapsibleContent>
          </CollapsibleComponent>
          </div>
          <h4><Link to="/member_area">Need help?</Link></h4>
          <Drawer
            anchor="bottom"
            open={ this.state.submitDrawerOpen }
            onClose={this.handleDismissClick.bind(this)}
          >
          {<div
            style={{ horizontalAlign: "center", textAlign: "center"}}
            >
            <p style={{fontWeight: 'bold', 'fontSize': '1.4rem', lineHeight: 0.4}}>Submit CID</p>
            <CommitHash
              guild={this.state.guild}
              hash={this.state.hash_output}
              handleWeb3Error={this.handleWeb3Error}
            />
           </div>}
            <div style={{lineHeight: 4}}>
            <p> </p>
            </div>
          </Drawer>
          <Drawer
            anchor="bottom"
            open={ this.state.messageDrawerOpen }
            onClose={this.handleDismissClick.bind(this)}
          >
          {<div
            style={{ horizontalAlign: "center", textAlign: "center"}}
            >
            <p style={{fontWeight: 'bold', 'fontSize': '1.4rem', lineHeight: 0.4}}>Submit Message</p>
            <CommitMessage
              guild={this.state.guild}
              hash={this.state.messageToSumbit}
              handleWeb3Error={this.handleWeb3Error}
            />
           </div>}
            <div style={{lineHeight: 4}}>
            <p> </p>
            </div>
          </Drawer>
          <Drawer
            anchor="bottom"
            open={ this.state.addMemberDrawerOpen }
            onClose={this.handleDismissClick.bind(this)}
          >
          {<div
            style={{ horizontalAlign: "center", textAlign: "center"}}
            >
            <p style={{fontWeight: 'bold', 'fontSize': '1.4rem', lineHeight: 0.4}}>Add Member</p>
            <AddMember
              guild={this.state.guild}
              memberAddress={this.state.memberAddress}
              handleWeb3Error={this.handleWeb3Error}
            />
           </div>}
            <div style={{lineHeight: 4}}>
            <p> </p>
            </div>
          </Drawer>
          <Drawer
            anchor="bottom"
            open={ this.state.removeMemberDrawerOpen }
            onClose={this.handleDismissClick.bind(this)}
          >
          {<div
            style={{ horizontalAlign: "center", textAlign: "center"}}
            >
            <p style={{fontWeight: 'bold', 'fontSize': '1.4rem', lineHeight: 0.4}}>Remove Member</p>
            <RemoveMember
              guild={this.state.guild}
              memberAddress={this.state.memberAddress}
              handleWeb3Error={this.handleWeb3Error}
            />
           </div>}
            <div style={{lineHeight: 4}}>
            <p> </p>
            </div>
          </Drawer>
          <Drawer
            anchor="bottom"
            open={ this.state.aspireMemberDrawerOpen }
            onClose={this.handleDismissClick.bind(this)}
          >
          {<div
            style={{ horizontalAlign: "center", textAlign: "center"}}
            >
            <p style={{fontWeight: 'bold', 'fontSize': '1.4rem', lineHeight: 0.4}}>Aspire Membership</p>
            <AspireMember
              guild={this.state.guild}
              handleWeb3Error={this.handleWeb3Error}
            />
           </div>}
            <div style={{lineHeight: 4}}>
            <p> </p>
            </div>
          </Drawer>
          <Drawer
            anchor="bottom"
            open={ this.state.removeAspireMemberDrawerOpen }
            onClose={this.handleDismissClick.bind(this)}
          >
          {<div
            style={{ horizontalAlign: "center", textAlign: "center"}}
            >
            <p style={{fontWeight: 'bold', 'fontSize': '1.4rem', lineHeight: 0.4}}>Remove Aspiring Membership</p>
            <RemoveAspireMember
              guild={this.state.guild}
              handleWeb3Error={this.handleWeb3Error}
            />
           </div>}
            <div style={{lineHeight: 4}}>
            <p> </p>
            </div>
          </Drawer>
          <PopUp
            popUpState={this.state.popUp}
            handlePopUpClick={this.handlePopUpClick}
            popUpMessage={this.state.popUpMessage}
          />
      </div>
    );
  }
}

// <View style={{flexDirection:'row'}}>

export default App;
