import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import App from "../components/ActionsAppMember"

const IndexPage = () => (
    <Layout>
      <SEO title="Member" keywords={['Proovify']} />
      <div style={{ maxWidth: `1200px`, marginBottom: `1.45rem` , 'marginLeft': '4px', 'marginRight': '4px'}} align="left">
        <App />
      </div>
    </Layout>
)

export default IndexPage
